<template>
  <div class="main">
    <template v-if="true">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <div class="box center mB20 box-bottom-border" style="padding-bottom: 20px;" >
          <el-form-item label="分析方法" prop="analyMethod">
            <el-select @change="changeMethod" v-model="ruleForm.analyMethod">
              <el-option
                v-for="item in analyMethodList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" label="分析时段" prop="startTime">
            <!-- @change="clearTable" -->
            <el-date-picker
              placeholder="请选择时间"
              type="datetime"
              @change="changeTime"
              :clearable="false"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              v-model="ruleForm.startTime"
            >
            </el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <!-- readonly -->
          <el-form-item prop="endTime">
            <el-date-picker
              @change="changeTime"
              placeholder="请选择时间"
              type="datetime"
              :clearable="false"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              v-model="ruleForm.endTime"
            >
            </el-date-picker>
          </el-form-item>
          <el-button @click="querryData" class="mL44 dataQuery">查询</el-button>
        </div>
        <template v-if="ruleForm.analyMethod === 1">
          <div class="box center mB20 "  >
            <el-form-item label="分析测点" prop="type">
              <el-select @change="changeType" v-model="ruleForm.type">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="ml24" prop="quota">
              <el-select @change="changeQuota" v-model="ruleForm.quota">
                <el-option
                  v-for="item in quotaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="ml24" prop="num">
              <el-select
                class="numSel"
                @change="changeNum"
                v-model="ruleForm.num"
              >
                <el-option
                  v-for="item in numList"
                  :key="item.id"
                  :label="item.code"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label-width="60px"
              label="时滞"
              class="ml24"
              prop="timeLate"
              style="margin-right: 44px;"
            >
              <el-input
                type="number"
                v-model="ruleForm.timeLate"
                placeholder="请输入"
              ></el-input>
              <span class="fs18 mL10">s</span>
            </el-form-item>
            <el-form-item label="数据类型" prop="dataType">
            <el-select
              class="dataSel"
              @change="clearTable"
              v-model="ruleForm.datatype_1"
            >
              <el-option
                v-for="item in dataTypeList[ruleForm.analyMethod - 1]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          </div>

        </template>
        <div v-show="ruleForm.analyMethod === 2" class="box center mB20 ">
          <el-form-item label="测点一" prop="type">
            <el-select @change="changeType" v-model="ruleForm.type">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" prop="quota">
            <el-select @change="changeQuota" v-model="ruleForm.quota">
              <el-option
                v-for="item in quotaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" prop="num">
            <el-select
              class="numSel"
              @change="changeNum"
              v-model="ruleForm.num"
            >
              <el-option
                v-for="item in numList"
                :key="item.id"
                :label="item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" label="数据类型" prop="dataType">
            <el-select
              class="dataSel"
              @change="clearTable"
              v-model="ruleForm.datatype_1"
            >
              <el-option
                v-for="item in dataTypeList[ruleForm.analyMethod - 1]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-show="ruleForm.analyMethod === 2" class="box center mB20 " >
          <el-form-item label="测点二" prop="type2">
            <el-select @change="changeType2" v-model="ruleForm.type2">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" prop="quota2">
            <el-select @change="changeQuota2" v-model="ruleForm.quota2">
              <el-option
                v-for="item in quotaList2"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" prop="num2">
            <el-select
              class="numSel"
              @change="changeNum2"
              v-model="ruleForm.num2"
            >
              <el-option
                v-for="item in numList2"
                :key="item.id"
                :label="item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml24" label="数据类型" prop="dataType">
            <el-select
              class="dataSel"
              @change="clearTable"
              v-model="ruleForm.datatype_2"
            >
              <el-option
                v-for="item in dataTypeList[ruleForm.analyMethod - 1]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="noDataMain" v-if="!showMain">
        <img src="../../../assets/images/noData.png" alt="" />
        <span>暂无数据</span>
      </div>
      <div class="midMain" v-if="showMain">
        <div class="mt14 newBox" v-show="!showNoData1">
          <div class="fs14 title txtCenter">测点{{ ruleForm.numName }}时程曲线</div>
          <div class="myChart" id="myChart1"></div>
        </div>
        <div class="noDataMain" v-show="showNoData1">
          <p class="emColor mB10">测点{{ ruleForm.numName }}时程曲线</p>
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>

        <div v-show="ruleForm.analyMethod === 2">
          <div v-show="!showNoData2" class="mt14 newBox">
            <div class="fs14 title txtCenter">测点{{ ruleForm.numName2 }}时程曲线</div>
            <div class="myChart" id="myChart2"></div>
          </div>
          <div class="noDataMain" v-show="showNoData2">
            <p class="emColor mB10">测点{{ ruleForm.numName2 }}时程曲线</p>
            <img src="../../../assets/images/noData.png" alt="" />
            <span>暂无数据</span>
          </div>
        </div>

        
        <div class="mt14 newBox" v-show="!showNoData3">
          <div class="mT10 fs14 title txtCenter">相关系数</div>
          <div class="myChart" id="myChart3"></div>
        </div>
        <div class="noDataMain" v-show="showNoData3">
          <p class="emColor mB10">相关系数</p>
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import echarts from "echarts";
import moment from "moment";
let option = {
  tooltip: {
    show: true,
    trigger: "axis",
  },
  grid: {
    top: "30",
    left: "90",
    right: "8",
    bottom: "80",
  },
  title: {
    show: false, // 是否显示title
    text: "暂无数据",
    top: "50",
    left: "center",
    // top: 'center',
    textStyle: {
      color: "white",
      fontSize: 16,
      fontWeight: 200,
    },
  },
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 40,
      end: 60,
      bottom: 0,
      fillerColor: "rgba(47,50,51,1)",
    },
    {
      type: "inside",
      realtime: true,
      start: 40,
      end: 60,
    },
  ],
  xAxis: {
    type: "category",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
      formatter: function (value) {
        return value.split(" ").join("\n");
      },
    },
    axisLine: {
      lineStyle: {
        color: "#9D9D9D",
        type: "dashed",
      },
    },
    axisTick: {
      show: false,
    },
    data: [],
  },
  yAxis: [
    {
      type: "value",
      name: "作用效应",
      nameTextStyle: {
        color: "#fff",
        fontSize: 14,
      },
      axisLabel: {
        color: "#fff",
        fontSize: 14,
      },
      axisLine: {
        lineStyle: {
          color: "#9D9D9D",
        },
      },
      splitLine: {
        lineStyle: {
          color: "#9D9D9D",
          type: "dashed",
        },
      },
      axisTick: {
        show: false,
      },
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#9D9D9D",
        },
      },
      axisTick: {
        show: false,
      },
    },
  ],
  series: [
    {
      name: "Y值",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#6DB5C7",
      },
      lineStyle: {
        width: 1,
      },
    },
  ],
};
export default {
  data() {
    return {
      showNoData1: false,
      showNoData2: false,
      showNoData3: false,
      ruleForm: {
        type: null,
        quota: null,
        num: null,
        numName: "",
        unit: "",
        type2: null,
        quota2: null,
        num2: null,
        numName2: "",
        unit2: "",
        analyMethod: 1,
        timeLate: 6000,
        datatype_1: 1,
        datatype_2: 1,
        startTime: "",
        endTime: "",
      },
      rules: {
        timeLate: [{ required: true, message: "请选择时滞", trigger: "blur" }],
      },
      typeList: [],
      quotaList: [],
      numList: [],
      quotaList2: [],
      numList2: [],
      analyMethodList: [
        { name: "自相关", id: 1 },
        { name: "互相关", id: 2 },
      ],
      dataTypeList: [
        [
          { name: "静态数据", id: 1 },
          { name: "基线校正后动态及振动", id: 2 },
        ],
        [
          { name: "静态数据", id: 1 },
          { name: "基线校正后", id: 3 },
          { name: "动态及振动数据", id: 4 },
          { name: "温度效应", id: 5 },
          { name: "运营荷载", id: 6 },
        ],
      ],
      myChart1: {},
      myChart2: {},
      myChart3: {},
      showMain: false,
      dataType: 14,
      first: 5,
    };
  },
  created() {},
  mounted() {
    // this.projectId = this.$store.state.projectId
    this.projectId = sessionStorage.getItem("projectId");
    // this.projectId = 10000
    this.first = 5;
    if (this.projectId == "10009") {
      this.ruleForm.timeLate = "6000";
    }

    this.getTime();
    this.getTypeList();
    this.getTypeList(2);
  },
  methods: {
    changeTime() {},

    changeMethod(val) {
      this.clearTable();
      this.getTime();
      this.getTypeList();
      this.ruleForm.timeLate = "";
      this.ruleForm.dataType = 1;
      if (val === 2) {
        this.getTypeList(2);
        this.rules.timeLate[0].required = false;
      } else {
        this.rules.timeLate[0].required = true;
      }
    },
    changeType() {
      this.clearTable();
      // this.getQuotaList();
      this.quotaList = [];
      this.ruleForm.quota = "";
      this.numList = [];
      this.ruleForm.num = "";
      this.getQuotaTypeList();
    },
    changeQuota() {
      this.clearTable();
      this.numList = [];
      this.ruleForm.num = "";
      this.getNumList();
    },
    changeNum(val) {
      this.clearTable();
      let numList = this.numList;
      for (let item of numList) {
        if (item.id === val) {
          this.ruleForm.numName = item.code;
          this.ruleForm.unit = item.unit;
          return;
        }
      }
    },
    changeType2() {
      this.clearTable();
      // this.getQuotaList(2);
      this.quotaList2 = [];
      this.ruleForm.quota2 = "";
      this.numList2 = [];
      this.ruleForm.num2 = "";
      this.getQuotaTypeList(2);
    },
    changeQuota2() {
      this.clearTable();
      this.numList2 = [];
      this.ruleForm.num2 = "";
      this.getNumList(2);
    },
    changeNum2(val) {
      this.clearTable();
      let numList2 = this.numList2;
      for (let item of numList2) {
        if (item.id === val) {
          console.log(item);
          this.ruleForm.numName2 = item.code;
          this.ruleForm.unit2 = item.unit;
          return;
        }
      }
    },
    clearTable() {
      this.showMain = false;
      this.querryData();
    },
    querryData() {
      if (this.ruleForm.analyMethod === 2) {
        this.rules.timeLate[0].required = false;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.getLineData();
        }
      });
    },
    getTime() {
      // this.ruleForm.endTime = new Date().Format('yyyy-MM-dd hh:mm')
      // this.ruleForm.startTime = new Date(new Date().getTime() - 30*60*1000).Format('yyyy-MM-dd hh:mm')

      var end = new Date().getTime();
      var end1 = new Date(end);
      console.log("end1", end1);

      this.ruleForm.endTime = moment(end1).format("yyyy-MM-DD HH:mm");
      console.log("end", this.ruleForm.endTime);

      // var start = new Date().getTime();
      var start = new Date(end - 24 * 60 * 60 * 1000);
      this.ruleForm.startTime = moment(start).format("yyyy-MM-DD HH:mm");
      console.log("end", this.ruleForm.startTime);
    },
    initEcharts(id, xData, yData) {
      let thisOption = { ...option };
      let max = Math.max.apply(null, yData).toFixed(4);
      let min = Math.min.apply(null, yData).toFixed(4);
      thisOption.yAxis[0].max = max;
      thisOption.yAxis[0].min = min;
      thisOption.xAxis.data = xData;
      thisOption.series[0].data = yData;
      switch (id) {
        case "myChart1":
          thisOption.yAxis[0].name = this.ruleForm.unit;
          thisOption.series[0].itemStyle.color = "#6DB5C7";
          if (yData.length == 0) {
            thisOption.title.show = true;
          } else {
            thisOption.title.show = false;
          }

          // this.myChart1 = echarts.init(document.getElementById(id));
          // this.myChart1.setOption(thisOption);
          // window.addEventListener("resize", () => {
          //   this.myChart1.resize();
          // });
          var name = this.ruleForm.unit;
          var lineColor = "#54a6ff";
          this.$chart2.line1(
            "myChart1",
            xData,
            yData,
            name,
            lineColor,
            max,
            min
          );

          break;
        case "myChart2":
          thisOption.yAxis[0].name = this.ruleForm.unit2;
          thisOption.series[0].itemStyle.color = "#6DB5C7";
          if (yData.length == 0) {
            thisOption.title.show = true;
          } else {
            thisOption.title.show = false;
          }

          // this.myChart2 = echarts.init(document.getElementById(id));
          // this.myChart2.setOption(thisOption);
          // window.addEventListener("resize", () => {
          //   this.myChart2.resize();
          // });
          var name = this.ruleForm.unit2;
          var lineColor = "#86eaff";
          this.$chart2.line1(
            "myChart2",
            xData,
            yData,
            name,
            lineColor,
            max,
            min
          );

          break;
        case "myChart3":
          thisOption.yAxis[0].name = "";
          thisOption.series[0].itemStyle.color = "#DBF804";
          console.log("yData", yData);

          if (yData.length == 0) {
            thisOption.title.show = true;
          } else {
            thisOption.title.show = false;
          }

          // this.myChart3 = echarts.init(document.getElementById(id));
          // this.myChart3.setOption(thisOption);
          // window.addEventListener("resize", () => {
          //   this.myChart3.resize();
          // });
          var name = "";
          var lineColor = "#d3ffef";
          this.$chart2.line1(
            "myChart3",
            xData,
            yData,
            name,
            lineColor,
            max,
            min
          );

          var chart1 = echarts.init(document.getElementById("myChart1"));
          var chart2 = echarts.init(document.getElementById("myChart2"));
          var chart3 = echarts.init(document.getElementById("myChart3"));
          echarts.connect([chart1, chart2, chart3]);

          break;
      }
    },
    getLineData() {
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00",
        targetId_1: this.ruleForm.num,
        lag: !this.ruleForm.timeLate ? 0 : this.ruleForm.timeLate,
        method: this.ruleForm.analyMethod,
        datatype_1: this.ruleForm.datatype_1,
      };
      if (this.ruleForm.analyMethod === 2) {
        params.datatype_2 = this.ruleForm.datatype_2;
        params.targetId_2 = this.ruleForm.num2;
      }
      console.log("params", params);
      this.$axios
        .get(`${this.baseURL}data/hisData/trend/`, { params })
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            console.log("相关性分析", res);
            let data = res.data;
            if(data.number && data.number.length){
              this.showNoData3 = false
            }else{
              this.showNoData3 = true
            }
            let datas1 = data.datas1;
            if(datas1 && datas1.length){
              this.showNoData1 = false
            }else{
              this.showNoData1 = true
            }
            let datas2 = data.datas2;
            if(datas2 && datas2.length){
              this.showNoData2 = false
            }else{
              this.showNoData2 = true
            }
            if (!datas1 && !datas1.length && !datas2 && !datas2.length) {
              this.showMain = false;
              return;
            }
            this.showMain = true;
            let xData = [];
            let yData = [];
            for (let item of datas1) {
              xData.push(item.time);
              yData.push(item.data[0]);
            }
            let xData2 = [];
            let yData2 = [];
            for (let item of datas2) {
              xData2.push(item.time);
              yData2.push(item.data[0]);
            }
            this.loadingPage.close();

            this.$nextTick(() => {
              this.initEcharts("myChart1", xData, yData);
              if (this.ruleForm.analyMethod === 2) {
                this.initEcharts("myChart2", xData2, yData2);
              }
              this.initEcharts("myChart3", data.time, data.number);
            });
          } else {
            this.loadingPage.close();
            this.$message.error(res.errMsg);
            this.showMain = false;
          }
        });
    },
    getTypeList(index) {
      // this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
      let params = {
        dataType: this.dataType,
        projectId: sessionStorage.getItem("projectId"),
      };

      this.$axios
        .get(`${this.baseURL}data_management/data_type/group`, { params })
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            this.typeList = res.data;
            if (!index) {
              this.ruleForm.type = res.data[0].id;
            } else {
              this.ruleForm.type2 = res.data[0].id;
            }
            // this.getQuotaList(index);
            this.getQuotaTypeList(index);
          } else {
            this.$message.error(res.errMsg);
          }
        });
    },
    getQuotaTypeList(index) {
      let typeGroupId = !index ? this.ruleForm.type : this.ruleForm.type2;
      let params = {
        dataType: this.dataType,
        groupId: typeGroupId,
        projectId: sessionStorage.getItem("projectId"),
      };
      this.$axios
        .get(`${this.baseURL}data_management/data_type/group/type`, { params })
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            if (!index) {
              this.ruleForm.quota = res.data[0].id;
              this.quotaList = res.data;
            } else {
              this.ruleForm.quota2 = res.data[0].id;
              this.quotaList2 = res.data;
            }
            this.getNumList(index);
          } else {
            this.$message.error(res.errMsg);
          }
        });
    },

    getQuotaList(index) {
      let typeGroupId = !index ? this.ruleForm.type : this.ruleForm.type2;
      let params = {
        typeGroupId,
      };
      this.$axios
        .get(`${this.baseURL}base/baseTargetType/byGroupId`, { params })
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            if (!index) {
              this.ruleForm.quota = res.data[0].id;
              this.quotaList = res.data;
            } else {
              this.ruleForm.quota2 = res.data[0].id;
              this.quotaList2 = res.data;
            }
            this.getNumList(index);
          } else {
            this.$message.error(res.errMsg);
          }
        });
    },
    getNumList(index) {
      let quota = !index ? this.ruleForm.quota : this.ruleForm.quota2;
      this.$axios
        .get(`${this.baseURL}base/baseTarget/type/${this.projectId}/${quota}`)
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            console.log(res);
            if (!index) {
              this.ruleForm.num = res.data[0].id;
              this.ruleForm.numName = res.data[0].code;
              this.ruleForm.unit = res.data[0].unit;
              this.numList = res.data;
            } else {
              this.ruleForm.num2 = res.data[0].id;
              this.ruleForm.numName2 = res.data[0].code;
              this.ruleForm.unit2 = res.data[0].unit;
              this.numList2 = res.data;
            }
            if (this.first > 4) {
              this.first++;
            } else {
              return;
            }

            if (this.first >= 7) {
              this.querryData();
              this.first = -1000;
            }
          } else {
            this.$message.error(res.errMsg);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 24px;
    .wid550 {
      width: 550px;
    }
    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 96px;
      padding: 0;
    }
    .smallLabel {
      .el-form-item__label {
        width: 80px;
        font-weight: 300;
        &.wid96 {
          width: 96px;
        }
      }
    }
    .el-form-item {
      margin-bottom: 0;
      display: flex;
      .numSel {
        .el-input {
          width: 180px;
        }
      }
      .dataSel {
        .el-input {
          width: 220px;
        }
      }
      &.mL10 {
        margin-left: 10px;
      }
      &.mB20 {
        margin-bottom: 20px;
      }
      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }
    .line {
      padding: 0 10px;
    }
    .el-input {
      width: 134px;
      height: 30px;
      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }
      .el-select__caret {
        line-height: 30px;
      }
      &.el-date-editor {
        .el-input__inner {
          padding: 0 12px;
        }
        .el-icon-date {
          display: none;
        }
      }
      &.el-date-editor,
      &.el-date-editor.el-input__inner {
        width: 200px;
      }
      &.el-date-editor .el-input__icon {
        display: none;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .midMain {
    margin-top: 10px;
    padding-right: 30px;
    .txtCenter {
      text-align: center;
    }
    .mt14 {
      margin-top: 10px;
    }
    .myChart {
      height: 220px;
      overflow: hidden;
    }
  }
}
@media screen and (max-width: 1600px) {
  .main {
    ::v-deep .el-form {
      .el-input {
        width: 120px;
        height: 30px;
        .el-input__inner {
          font-size: 14px;
        }
      }
      button.el-button {
        width: 90px;
        height: 30px;
        &.mL44 {
          margin-left: 20px !important;
        }
      }
    }
    .midMain {
      margin-top: 26px;
      .left {
        .mt14 {
          margin-top: 12px;
        }
      }
    }
  }
}
.noDataMain {
  padding-top: 40px;
  padding-bottom: 40px;
}
.title {
  position: relative;
  left: 14px;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
.title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
</style>
